<template>
  <div>
    <!-- Node bulunamaması durumunda   -->
    <div
      v-if="page_mode=='error'"
      class="misc-inner p-2 p-sm-3"
    >
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{$t('survey_viewer.page_not_found')}} 🕵🏻‍♀️
        </h2>
        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
    <!-- Node bulunamaması durumunda   -->

    <!-- Node anket durumunda   -->
    <div v-if="page_mode=='survey'">
      <Survey :survey="survey" />
    </div>
    <!-- Node anket durumunda   -->

    <!-- Node biolink durumunda   -->
    <div v-if="page_mode=='biolink'">
      <b-row class="justify-content-center my-5">
        <b-col cols="6">
          <b-img
              src="https://www.king.com.tr/cdn/shop/files/King_Logo_2_250x@2x.png?v=1719294309"
              style="max-width: 100%"
          />
        </b-col>
      </b-row>
      <b-row class="justify-content-center mx-1">
          <span>Seri No:111</span><br>
          <span>İhtiyaçlarınızı hızlı ve kolay bir şekilde karşılamak için aşağıdaki bağlantıları kullanabilirsiniz.</span>
      </b-row>
      <b-row class="justify-content-center mx-50">
        <b-col class="my-1" cols="12">
          <b-button href="https://rima.com.tr/wp-content/uploads/2019/05/garanti-belgesiC.pdf" target="_blank" variant="primary" block>Kullanım Kılavuzu</b-button>
        </b-col>
        <b-col class="my-1" cols="12">
          <b-button href="https://rima.com.tr/wp-content/uploads/2019/05/garanti-belgesiC.pdf" target="_blank" variant="secondary" block>Garanti Belgesi</b-button>
        </b-col>
        <b-col class="my-1" cols="12">
          <b-button href="https://youtube.com" target="_blank" variant="info" block>Kullanım Videosu</b-button>
        </b-col>
        <b-col class="my-1" cols="12">
          <b-button href="https://app.geribildir.io/node_query?url_token=9d89a3d4-cfb9-4b16-899e-0dcb4b001e4c" target="_blank" variant="warning" block>Geri Bildirim Gönder</b-button>
        </b-col>
      </b-row>
      <b-row class="justify-content-center text-center mt-5">
        <b-col class="border"><feather-icon icon="FacebookIcon" /></b-col>
        <b-col class="border"><feather-icon icon="YoutubeIcon" /></b-col>
        <b-col class="border"><feather-icon icon="LinkedinIcon" /></b-col>
        <b-col class="border"><feather-icon icon="InstagramIcon" /></b-col>
      </b-row>
    </div>
    <!-- Node biolink durumunda   -->

    <!-- Footer -->
    <footer class="footer footer-light">
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
  </div>
</template>

<script>
import 'survey-core/defaultV2.css'
import { Model } from 'survey-core'
import { Survey } from 'survey-vue-ui'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import {BButton, BCol, BImg, BRow} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BImg,
    BRow,
    BCol,
    AppFooter,
    Survey,
  },
  data() {
    return {
      page_mode: null,
      url_token: router.currentRoute.query.url_token,
      downImg: require('@/assets/images/pages/error.svg'),
      survey: null,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {
    if (this.url_token == undefined) {
      this.page_mode = 'error'
    } else if (this.url_token == 'f8606e33-f5e4-48e8-bbae-32e01371b4a4') {
      this.page_mode = 'biolink'
    } else {
      useJwt.getNodeUrl({
        url_token: this.url_token,
      })
        .then(response => {
          if (response.data.status == false) {
            this.page_mode = 'error'
          } else if (response.data.page_mode == 'survey') {
            const { survey_json } = response.data
            this.survey = new Model(survey_json)
            this.survey.onComplete.add(this.alertResults)
            this.page_mode = 'survey'
            this.survey.onUploadFiles.add((_, options) => {
              const formData = new FormData()
              formData.append('survey_url_token', this.url_token)
              options.files.forEach(file => { formData.append(file.name, file) })

              useJwt.createnodesurveyanswermedia(formData)
                .then(response => { options.callback(options.files.map(file => ({ file, content: response.data[file.name] }))) })
                .catch(error => {
                  console.error('Error: ', error)
                  options.callback([], ['An error occurred during file upload.'])
                })
            })
            this.survey.onDownloadFile.add((_, options) => {
              useJwt.nodesurveyanswermediaDetail(options.content)
                .then(aa => {
                  fetch(aa.data.file)
                    .then(response => response.blob())
                    .then(blob => {
                      const file = new File([blob], options.fileValue.name, { type: options.fileValue.type })
                      const reader = new FileReader()
                      reader.onload = e => { options.callback('success', e.target.result) }
                      reader.readAsDataURL(file)
                    })
                    .catch(error => {
                      console.error('Error: ', error)
                      options.callback('error')
                    })
                })
                .catch(error => {
                  console.error('Error: ', error)
                  options.callback('error')
                })
            })
            this.survey.onClearFiles.add(async (_, options) => {
              if (!options.value || options.value.length === 0) return options.callback('success')
              if (!options.fileName && !!options.value) {
                for (const item of options.value) { await this.deleteFile(item.content, options) }
              } else {
                const fileToRemove = options.value.find(item => item.name === options.fileName)
                if (fileToRemove) {
                  await this.deleteFile(fileToRemove.content, options)
                } else {
                  console.error(`File with name ${options.fileName} is not found`)
                  options.callback('error')
                }
              }
            })
          } else {
            this.page_mode = 'biolink'

          }
        })
        .catch(error => {
          console.log(error.response.data.detail != undefined ? error.response.data.detail : error.response.data)
          this.page_mode = 'error'
        })
    }
  },
  methods: {
    async  deleteFile(name, options) {
      useJwt.nodesurveyanswermediaDelete(name)
        .then(response => {
          console.log(`File ${name} was deleted successfully`)
          options.callback('success')
        })
        .catch(error => {
          console.error('Error while deleting file: ', error)
          options.callback('error')
        })
    },
    alertResults(sender) {
      const results = JSON.stringify(sender.data)
      useJwt.setSurveyAnswer({
        url_token: this.url_token,
        answer_survey_json: results,
      })
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error.response.data.detail != undefined ? error.response.data.detail : error.response.data)
        })
    },
  },
}
</script>
<style>
.sd-root-modern {
  height: 100vh;
}

</style>
